/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-blue: #301a52;
  --ff-primary: "Lato", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 1rem;
  width: 85%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Add shadow around the body */
  border-radius: 10px; /* Optional: Add rounded corners to make the effect cleaner */
}
/* Media query for smaller screens */
@media(max-width: 768px) {
  body {
    width: 100%;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  /* font-weight: 700; */
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-8);
}
/* section */
.section {
  padding: 5rem 0;
  scroll-margin-top: 4rem;
}
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h2 {
  text-transform: uppercase;
}
.section-title span {
  color: var(--clr-primary-5);
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

/*
=============== 
Hero
===============
*/

.hero {
  min-height: 100vh;
  background: var(--clr-primary-5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.test {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 20vw; /* Full viewport height */
  text-align: center;
}
.homepage {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.pricing {
  min-height: 50vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.mainFeatures {
  min-height: 50vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.results {
  min-height: 100vh;
  background: var(--clr-primary-5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-banner {
  text-align: center;
  color: var(--clr-white);
  padding: 0 3rem;
}
.hero-banner h1 {
  text-transform: uppercase;
}
.hero-banner p {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
}
.hero-btn {
  padding: 0.9rem 1.6rem;
  font-size: 1.25rem;
  background: var(--clr-white);
  color: var(--clr-primary-5);
  margin: 20px;
}
.hero-btn:hover {
  background: transparent;
  color: var(--clr-white);
  border-color: var(--clr-white);
}
@media screen and (min-width: 768px) {
  .hero {
    background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
  }
  .hero-banner {
    padding: 0;
  }
  .hero-banner p {
    max-width: 45rem;
  }
}

/*
=============== 
Navbar
===============
*/
/* add :not(.nav-logo) to img */
.roads {
  color: var(--clr-primary-5);
  font-size: 0.875rem;
}
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-blue);
  box-shadow: var(--dark-shadow);
  z-index: 2;
  height: 4rem;
  display: flex;
  align-items: center;
}
.nav-icons {
  display: none;
}
.nav-center {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
  font-size: xx-large;
  height: 100px; /* Adjust this to your desired height */
  padding: 0 20px; /* Optional: Adjust padding as needed */
}
.nav-toggle {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  cursor: pointer;
  transition: var(--transition);
}
.nav-toggle:hover {
  transform: scale(1.2);
}
.nav-link {
  display: block;
  padding: 1rem 2rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  transition: var(--transition);
  color: var(--clr-white);
  cursor: pointer;
  font-size: 1rem;
}
.nav-link:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-8);
  padding-left: 2.25rem;
}
/* nav toggle functionality */
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-links {
  height: 280px;
}
/* nav at bigger screen size */
@media screen and (min-width: 992px) {
  /* hide nav toggle button */
  .nav-toggle {
    display: none;
  }
  /* show links */
  .nav-links {
    height: auto;
    display: flex;
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* optional */
    flex-wrap: wrap;
  }

  .nav-link {
    padding: 0 0;
  }
  .nav-link:hover {
    padding: 0;
    color: var(--clr-primary-5);
    background: transparent;
  }
  .nav-icons {
    display: flex;
  }

  .nav-link {
    margin-right: 0.7rem;
  }
  .nav-icon {
    margin-right: 0.7rem;
    color: var(--clr-primary-5);
    font-size: 1.2rem;
    transition: var(--transition);
  }
  .nav-icon:hover {
    color: var(--clr-primary-8);
  }
}

.start-stop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 20vw; /* Full viewport height */
  text-align: center;
}

.background-image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay-content {
  position: absolute;
  top: 20%;
  left: 5%;
  transform: translate(-20%, -5%);
  text-align: center;
  color: white;
  padding: 1rem; /* Add padding to ensure text is not cramped */
  width: 90%; /* Make overlay content adapt to the image width */
}
.overlay-content h3 {
  color: red;
}
.what-is-celpip {
  display: block;
  width: 75%;  /* Adjusted to 80% width */
  margin: 0 auto;  /* Center align the div horizontally */
  height: auto;
}


/*
=============== 
Mic Starts
===============
*/
.mic-icon svg {
  fill: #666; /* Default color of mic icon */
  width: 20vw; /* 20% of the viewport width */
  height: 20vw; /* 20% of the viewport height */
  max-width: 150px; /* Maximum size of the icon */
  max-height: 150px; /* Maximum size of the icon */
  margin-bottom: 20px;
}

.mic-icon.recording svg {
  animation: pulse 1s infinite;
  fill: red; /* Color of mic icon when recording */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.controls {
  margin-top: 20px;
}
/*
=============== 
Mic Ends
===============
*/
.samples {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.samples-container {
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
}

.prompts-list {
  display: flex;
  flex-direction: column;
  background: var(--clr-primary-8);
  min-height: 100vh;
}

.samples-prompt-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.prompts-list-item {
  background: var(--clr-primary-8);
}

.prompts-list-item:hover {
  background: var(--clr-primary-9);
}

.writing-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.writing-test-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 50vw; /* Full viewport height */
}
